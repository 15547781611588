import MenuIcon from '@mui/icons-material/Menu';
import { Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { withStyles } from '@mui/styles';
import React, { Component } from 'react';
import {
  Link, Route,
  Switch
} from 'react-router-dom';
import { enqueueSnackbar } from 'notistack'
import LoadingIndicator from '../Components/common/LoadingIndicator';
import NotFound from '../Components/common/NotFound';
import PrivateRoute from '../Components/common/PrivateRoute';
import { ACCESS_TOKEN } from '../constants';
import { MenuList1, MenuList2 } from '../constants/MenuList';
import Home from '../home/Home';
import ReportForm from '../report/ReportForm';
import Checklists from '../report/ReportList';
import RoutingForm from '../Routing/RoutingForm';
import RoutingList from '../Routing/RoutingList';
import UploadCSV from '../Routing/UploadCSV';
import UploadReconCSV from '../Routing/UploadReconCSV';
import Login from '../user/login/Login';
import OAuth2RedirectHandler from '../user/oauth2/OAuth2RedirectHandler';
import Profile from '../user/profile/Profile';
import Signup from '../user/signup/Signup';
import { getCurrentUser } from '../util/APIUtils';
import './App.css';


const drawerWidth = 240;

const useStyles = theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});


const MenuItems = {

}


class App extends Component {


  constructor(props) {
    super(props);
    this.state = {
      authenticated: false,
      currentUser: null,
      loading: false,
      isDrawerOpened: false,
    }

    this.loadCurrentlyLoggedInUser = this.loadCurrentlyLoggedInUser.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  loadCurrentlyLoggedInUser() {
    this.setState({
      loading: true
    });

    getCurrentUser()
      .then(response => {
        this.setState({
          currentUser: response,
          authenticated: true,
          loading: false
        });
      }).catch(error => {
        this.setState({
          loading: false
        });
      });
  }

  handleLogout() {
    localStorage.removeItem(ACCESS_TOKEN);
    this.setState({
      authenticated: false,
      currentUser: null
    });
    enqueueSnackbar("You're safely logged out!")
  }

  componentDidMount() {
    this.loadCurrentlyLoggedInUser();
  }

  toggleDrawerStatus = () => {
    this.setState({
      isDrawerOpened: !this.state.isDrawerOpened,
    })
  }




  renderDrawer() {
    const { classes } = this.props;
    const menuItems1 = this.state.authenticated ? MenuList1[this.state.currentUser.role] : MenuList1["loggedOut"];
    const menuItems2 = this.state.authenticated ? MenuList2["loggedIn"] : MenuList2["loggedOut"];

    return (
      <div>
        <div className={classes.toolbar} style={{ backgroundColor: "#2188dc" }} />
        <Divider />
        <List>
          {menuItems1.map((item, index) => (
            <Link to={item.path} style={{ color: 'black' }} key={index}>
              <ListItem button >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            </Link>
          ))}
        </List>
        {this.state.authenticated ? <Divider /> : null}
        <List>
          {menuItems2.map((item, index) => (
            <Link to={item.path}
              key={index}
              onClick={item.name == 'Logout' ? this.handleLogout : null} style={{ color: 'black' }}
            >
              <ListItem button >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            </Link>
          ))}
        </List>
      </div>
    )
  }

  render() {

    if (this.state.loading) {
      return <LoadingIndicator />
    }

    const { isDrawerOpened } = this.state;
    const { classes, window, theme } = this.props;
    const container = window !== undefined ? () => window().document.body : undefined;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar} style={{ backgroundColor: "#2188dc" }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={this.toggleDrawerStatus}
              className={classes.menuButton}
              size="large">
              <MenuIcon />
            </IconButton>
            <Link to="/" style={{ color: '#FFF' }}>
              <Typography variant="h6" noWrap>
                Chakkizza
              </Typography>
            </Link>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor="left"
              open={this.state.isDrawerOpened}
              onClose={this.toggleDrawerStatus}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {this.renderDrawer()}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {this.renderDrawer()}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <PrivateRoute exact path="/" authenticated={this.state.authenticated} currentUser={this.state.currentUser} component={Home}></PrivateRoute>
            <PrivateRoute path="/profile" authenticated={this.state.authenticated} currentUser={this.state.currentUser}
              component={Profile}></PrivateRoute>
            <PrivateRoute path="/Checklists" authenticated={this.state.authenticated} currentUser={this.state.currentUser}
              component={Checklists}></PrivateRoute>
            <PrivateRoute path="/Report" authenticated={this.state.authenticated} currentUser={this.state.currentUser}
              component={ReportForm}></PrivateRoute>
            <PrivateRoute path="/routing" authenticated={this.state.authenticated} currentUser={this.state.currentUser}
              component={UploadCSV}></PrivateRoute>
            <PrivateRoute path="/recon" authenticated={this.state.authenticated} currentUser={this.state.currentUser}
              component={UploadReconCSV}></PrivateRoute>
            <PrivateRoute path="/routingList" authenticated={this.state.authenticated} currentUser={this.state.currentUser}
              component={RoutingList}></PrivateRoute>
            <PrivateRoute path="/viewRouting" authenticated={this.state.authenticated} currentUser={this.state.currentUser}
              component={RoutingForm}></PrivateRoute>
            <Route path="/login"
              render={(props) => <Login authenticated={this.state.authenticated} {...props} />}></Route>
            <Route path="/signup"
              render={(props) => <Signup authenticated={this.state.authenticated} {...props} />}></Route>
            <Route path="/oauth2/redirect" component={OAuth2RedirectHandler}></Route>
            <Route component={NotFound}></Route>
          </Switch>
        </main>
      </div>
    );

  }
}

export default withStyles(useStyles)(App);
