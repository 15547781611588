import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import LoadingIndicator from '../Components/common/LoadingIndicator';
import { ReportMenu } from '../constants/MenuList';
import './ReportList.css';


class ReportList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reportList: []
        };
    }

    componentDidMount() {
        //console.log('ReportList mounted');
        //this.setState({ loading: true });
        /* getReportList()
            .then(response => {
                //console.log('getReportList API response', response);
                this.setState({ reportList: response, loading: false })
            }).catch(error => {
                this.setState({ loading: false });
                enqueueSnackbar((error && error.message) || 'Oops! Something went wrong. Please try again!', { variant: 'error' })
            }); */

    }


    render() {

        const ReportItems = this.props.currentUser ? ReportMenu[this.props.currentUser.role] : [];

        if (this.state.loading) {
            return <LoadingIndicator />
        }
        return (
            <div className="reportList-container">
                <div className="container">
                    <ol>
                        {ReportItems.map((item, index) => (
                            <li key={item?.name || index} style={{ marginBottom: 10 }}><NavLink to={{
                                pathname: "/Report",
                                formProps: { reportName: item.name, jasperFileName: item.key }
                            }}>{item.name}</NavLink></li>
                        ))}
                    </ol>
                </div>
                {/* <div className="container">
                    <ol>{this.state.reportList && this.state.reportList.map((report) => {
                        return (
                            <li key={report.id}><NavLink to={{
                                pathname: "/Report",
                                formProps: { reportName: report.reportName }
                            }}
                            >{report.reportName}</NavLink></li>
                        )
                    })}</ol>
                </div> */}
            </div>
        )
    }
}

export default ReportList;