import React, { Component } from 'react';
import { enqueueSnackbar } from 'notistack'
import Button from '../Components/UI/Button/Button';
import Input from '../Components/UI/Input/Input';
import LoadingIndicator from '../Components/common/LoadingIndicator';
import { uploadFile } from '../util/APIUtils';
import './UploadCSV.css';
//import Button from '@mui/material/Button';


class UploadCSV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uploadForm: {
                file: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'file',
                        placeholder: 'Routing CSV:',
                        accept: ".csv, text/csv, text/*,"
                    },
                    value: '',
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                },
                date: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'date',
                        placeholder: 'Delivery Date:',
                        //max: this.getTomorrowDate(),
                        min: new Date().toISOString().split("T")[0]
                        //new Date().toISOString().split("T")[0]
                    },
                    value: new Date().toISOString().split("T")[0],
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                }
            },
            formIsValid: false,
            loading: false
        };
    }

    componentDidMount() {
        //console.log('form mounted.')
    }

    getTomorrowDate() {
        const today = new Date()
        const tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        return tomorrow.toISOString().split("T")[0]
    }

    nextPath(path, date) {
        this.props.history.push({
            pathname: path,
            formProps: { deliveryDate: date }
        });
    }

    submitCSV = (event) => {
        this.setState({ loading: true });
        event.preventDefault();
        const data = {};
        for (let formElementIdentifier in this.state.uploadForm) {
            data[formElementIdentifier] = this.state.uploadForm[formElementIdentifier];
        }
        //alert(JSON.stringify(data));
        //return;
        const formData = new FormData();


        //formData.append('file', this.state.uploadForm.file.value);
        //MIME type issue on windows-----------
        //formData.append("file", data.file.file);
        formData.append('file', new Blob([data.file.file], { type: 'text/csv' }), data.file.value.replace(/.*[\/\\]/, ''));
        //-------------------------------------
        formData.append('date', data.date.value);

        let day = new Date(data.date.value).getUTCDay();
        /* if([4].includes(day)){
            alert('Thursdays are not allowed');
            this.setState({ loading: false });
            return;
        } */
        //console.log(data.file.file);

        /* for (var key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
        } */
        uploadFile(formData)
            .then(response => {

                //console.log('uploadFile API response', response);
                //this.setState({ loading: false });
                alert("File uploaded successfully. Please wait as the file is being processed.")

                setTimeout(() => {
                    this.nextPath('/viewRouting', data.date.value);
                }, 5000);
            }).catch(error => {
                enqueueSnackbar((error && error.message) || 'Oops! Something went wrong. Please try again!', { variant: 'error' })
                this.setState({ loading: false });
            })




        /* fetch('http://localhost:8080/upload', {
            headers: {
                'Authorization': 'Bearer ' +
                    'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxIiwiaWF0IjoxNjEwNTE3MjU4LCJleHAiOjE2MTEzODEyNTh9.XVvYOnG5OJuC-N7X2p4wxxjU36t9T2y9Mcbu5T-ioIyppGbDStdPYCTyBYOdF1c7imNaljAUPbMylOImbn_2iA'
            },
            method: 'post',
            body: formData
        }).then(res => {
            if (res.ok) {
                console.log(res.data);
                alert("File uploaded successfully.")
            }
        }).catch(error => {
            enqueueSnackbar((error && error.message) || 'Oops! Something went wrong. Please try again!', { variant: 'error' })
            this.setState({ loading: false });
        });; */

    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        return isValid;
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedUploadForm = {
            ...this.state.uploadForm
        };
        const updatedFormElement = {
            ...updatedUploadForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.file = event.target.files && event.target.files[0] ? event.target.files[0] : null;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedUploadForm[inputIdentifier] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedUploadForm) {
            formIsValid = updatedUploadForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({ uploadForm: updatedUploadForm, formIsValid: formIsValid });

        //console.log(JSON.stringify(updatedUploadForm));
    }

    render() {
        const formElementsArray = [];
        for (let key in this.state.uploadForm) {
            formElementsArray.push({
                id: key,
                config: this.state.uploadForm[key]
            });
        }
        let form = (
            <form onSubmit={this.submitCSV} encType="multipart/form-data">
                {formElementsArray.map(formElement => {
                    return (
                        <div key={formElement.id}>
                            <label className="input-label">{formElement.config.elementConfig.placeholder}</label>
                            <Input
                                key={formElement.id}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                //value={formElement.config.value}
                                invalid={!formElement.config.valid}
                                shouldValidate={formElement.config.validation}
                                touched={formElement.config.touched}
                                changed={(event) => this.inputChangedHandler(event, formElement.id)}
                            />
                        </div>
                    )
                }
                )}
                <Button btnType="Success" disabled={!this.state.formIsValid}>Upload</Button>
                {/* <Button variant="contained" disabled={!this.state.formIsValid} style={{ marginTop: 10 }} >Upload</Button> */}
            </form>
        );
        if (this.state.loading) {
            form = <LoadingIndicator />;
        }
        return (
            /* <div className="report-container">
                <div className="report-content">
                    <h1 className="report-title">Upload routing CSV</h1>
                    <div className="report-form">
                        {form}
                    </div>
                </div>
            </div> */
            <div className="report-container">
                <div className="report-content">
                    <h1 className="report-title">Upload routing CSV</h1>
                    <div className="report-form">
                        {form}
                    </div>
                </div>
            </div>
        );
    }
}

export default UploadCSV;

