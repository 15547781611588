import React, { Component } from 'react';
import { enqueueSnackbar } from 'notistack'
import LoadingIndicator from '../Components/common/LoadingIndicator';
// import Button from '../Components/UI/Button/Button';
import Input from '../Components/UI/Input/Input';
import { generateReportAsync, getReport } from '../util/APIUtils';
import './ReportForm.css';
import FilesTable from './component/FilesTable';
import Button from '@mui/material/Button';
import { FormHelperText } from '@mui/material';


class ReportForm extends Component {
    constructor(props) {
        super(props);
        let reportForm;
        let reportFormat;
        switch (this.props.location.formProps.jasperFileName) {
            // case 'DeliveryCheckList_Lazydoor':
            //     reportForm = {};
            //     reportFormat = 'csv';
            //     break;
            case 'OrderList_Logibee':
                reportForm = {
                    deliveryDate: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'date',
                            placeholder: 'Estimated Delivery Date >='
                        },
                        value: new Date().toISOString().split("T")[0],
                        validation: {
                            required: true
                        },
                        valid: true,
                        touched: true
                    }
                };
                reportFormat = 'xlsx';
                break;
            case 'OrderList_Woohoo_Logibee':
                reportForm = {};
                reportFormat = 'xlsx';
                break;
            case 'ProdcutWiseQuantity':
                reportForm = {
                    deliveryDate: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'date',
                            placeholder: 'Estimated Delivery Date:'
                        },
                        value: new Date().toISOString().split("T")[0],
                        validation: {
                            required: true
                        },
                        valid: true,
                        touched: true
                    }
                };
                reportFormat = 'csv';
                break;
            case 'DailyProductVsQuantity':
                reportForm = {
                    deliveryDate: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'date',
                            placeholder: 'Delivery Date:'
                        },
                        value: new Date().toISOString().split("T")[0],
                        validation: {
                            required: true
                        },
                        valid: true,
                        touched: true
                    }
                };
                reportFormat = 'csv';
                break;
            case 'DailyTransactions':
                reportForm = {
                    deliveryDate: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'date',
                            placeholder: 'Delivery Date:'
                        },
                        value: new Date().toISOString().split("T")[0],
                        validation: {
                            required: true
                        },
                        valid: true,
                        touched: true
                    }
                };
                reportFormat = 'csv';
                break;
            case 'DailyTransactions':
                reportForm = {
                    deliveryDate: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'date',
                            placeholder: 'Delivery Date:'
                        },
                        value: new Date().toISOString().split("T")[0],
                        validation: {
                            required: true
                        },
                        valid: true,
                        touched: true
                    }
                };
                reportFormat = 'csv';
                break;
            case 'DailyOrders':
                reportForm = {
                    deliveryDate: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'date',
                            placeholder: 'Order Date:',
                            min: this.getYestDate()
                        },
                        value: this.getYestDate(),//new Date().toISOString().split("T")[0],
                        validation: {
                            required: true
                        },
                        valid: true,
                        touched: true
                    }
                };
                reportFormat = 'csv';
                break;
            case 'ReconReport':
                reportForm = {
                    reconFlag: {
                        elementType: 'select',
                        elementConfig: {
                            type: 'select',
                            placeholder: 'Recon Flag',
                            options: [
                                {
                                    "displayValue": "--Select--",
                                    "value": "--Select--",
                                },
                                {
                                    "displayValue": "All",
                                    "value": "A",
                                },
                                {
                                    "displayValue": "N",
                                    "value": "N",
                                },
                                {
                                    "displayValue": "Y",
                                    "value": "Y",
                                }

                            ]
                        },
                        value: "--Select--",
                        validation: {
                            required: true,
                            select: true
                        },
                        valid: false,
                        touched: false
                    }
                };
                reportFormat = 'csv';
                break;
            case 'OrderList_dunzo':
                reportForm = {
                    deliveryDate: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'date',
                            placeholder: 'Delivery Date:'
                        },
                        value: new Date().toISOString().split("T")[0],
                        validation: {
                            required: true
                        },
                        valid: true,
                        touched: true
                    }
                };
                reportFormat = 'csv';
                break;
            case 'DeliveryExecDistance':
                reportForm = {
                    fromDate: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'date',
                            placeholder: 'From Date:'
                        },
                        value: '',
                        validation: {
                            required: true
                        },
                        valid: false,
                        touched: true
                    },
                    toDate: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'date',
                            placeholder: 'To Date:'
                        },
                        value: '',
                        validation: {
                            required: true
                        },
                        valid: false,
                        touched: true
                    }
                };
                reportFormat = 'csv';
                break;
            case 'PackagingCheckList_Woohoo':
                reportForm = {
                    orders: {
                        elementType: 'textarea',
                        elementConfig: {
                            type: 'text',
                            placeholder: 'Comma Seperated Orders'
                        },
                        value: '',
                        validation: {
                            required: true
                        },
                        valid: false,
                        touched: false
                    }
                    // fromOrder: {
                    //     elementType: 'input',
                    //     elementConfig: {
                    //         type: 'text',
                    //         placeholder: 'From Order:'
                    //     },
                    //     value: '',
                    //     validation: {
                    //         required: true
                    //     },
                    //     valid: false,
                    //     touched: false
                    // },
                    // toOrder: {
                    //     elementType: 'input',
                    //     elementConfig: {
                    //         type: 'text',
                    //         placeholder: 'To Order:'
                    //     },
                    //     value: '',
                    //     validation: {
                    //         required: true
                    //     },
                    //     valid: false,
                    //     touched: false
                    // }
                };
                reportFormat = 'pdf';
                break;
            case 'DeliveryCheckList_Woohoo':
                reportForm = {
                    orders: {
                        elementType: 'textarea',
                        elementConfig: {
                            type: 'text',
                            placeholder: 'Comma Seperated Orders'
                        },
                        value: '',
                        validation: {
                            required: true
                        },
                        valid: false,
                        touched: false
                    }
                    // fromOrder: {
                    //     elementType: 'input',
                    //     elementConfig: {
                    //         type: 'text',
                    //         placeholder: 'From Order:'
                    //     },
                    //     value: '',
                    //     validation: {
                    //         required: true
                    //     },
                    //     valid: false,
                    //     touched: false
                    // },
                    // toOrder: {
                    //     elementType: 'input',
                    //     elementConfig: {
                    //         type: 'text',
                    //         placeholder: 'To Order:'
                    //     },
                    //     value: '',
                    //     validation: {
                    //         required: true
                    //     },
                    //     valid: false,
                    //     touched: false
                    // }
                };
                reportFormat = 'pdf';
                break;
            case 'ProcessingOrdersPackets':
                reportForm = {
                    deliveryDate: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'date',
                            placeholder: 'Estimated Delivery Date:'
                        },
                        value: '',
                        validation: {
                            required: true
                        },
                        valid: false,
                        touched: false
                    },
                    fromDateTime: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'datetime-local',
                            placeholder: 'From Date Time:'
                        },
                        value: '',
                        validation: {
                            required: true
                        },
                        valid: false,
                        touched: false
                    },
                    toDateTime: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'datetime-local',
                            placeholder: 'To Date Time:'
                        },
                        value: '',
                        validation: {
                            required: true
                        },
                        valid: false,
                        touched: false
                    }
                };
                reportFormat = 'pdf';
                break;
            case 'COD_Recon':
                reportForm = {
                    deliveryDate: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'date',
                            placeholder: 'Delivery Date'
                        },
                        value: new Date().toISOString().split("T")[0],
                        validation: {
                            required: true
                        },
                        valid: true,
                        touched: true
                    }
                };
                reportFormat = 'xlsx';
                break;
            default:
                reportForm = {
                    deliveryDate: {
                        elementType: 'input',
                        elementConfig: {
                            type: 'date',
                            placeholder: 'Delivery Date:'
                        },
                        value: new Date().toISOString().split("T")[0],
                        validation: {
                            required: true
                        },
                        valid: true,
                        touched: true
                    }
                };
                reportFormat = 'pdf';
        }
        this.state = {
            reportForm: reportForm,
            formIsValid:
                this.props.location.formProps.jasperFileName == 'ProcessingOrdersPackets' ||
                    this.props.location.formProps.jasperFileName == 'DeliveryCheckList_Woohoo' || this.props.location.formProps.jasperFileName == 'PackagingCheckList_Woohoo' || this.props.location.formProps.jasperFileName == 'ReconReport' || this.props.location.formProps.jasperFileName == 'DeliveryExecDistance' ? false : true,//uncomment if parameters are added//Object.keys(reportForm).length === 0 && reportForm.constructor === Object ? true : false,
            loading: false,
            reportName: this.props.location.formProps.reportName,
            jasperFileName: this.props.location.formProps.jasperFileName,
            reportFormat: reportFormat,
            submittedState: false
        };
    }

    getYestDate() {
        const today = new Date()
        const yest = new Date(today)
        yest.setDate(yest.getDate() - 1)
        return yest.toISOString().split("T")[0]
    }

    componentDidMount() {
        //console.log('form mounted.')
        //console.log(this.state.reportForm)
    }
    generateReport = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        const formData = {};
        for (let formElementIdentifier in this.state.reportForm) {
            formData[formElementIdentifier] = this.state.reportForm[formElementIdentifier].value;
        }
        //console.log('formData: ', formData);
        //console.log('deliveryDate ', formData.deliveryDate);
        const getReportRequest = {
            "format": this.state.reportFormat,
            "reportName": this.state.jasperFileName,//remove hardcoding
            "orders": formData.orders,
            "deliveryDate": formData.deliveryDate,
            "recon": formData.reconFlag,
            "fromDate": formData.fromDate,
            "toDate": formData.toDate,
            "fromOrder": formData.fromOrder,
            "toOrder": formData.toOrder,
            "fromDateTime": formData.fromDateTime,
            "toDateTime": formData.toDateTime
        }
        // console.log(JSON.stringify(getReportRequest));
        // return;

        generateReportAsync(getReportRequest)
            .then(response => {

                // console.log('generateReportAsync API response', response);
                if (response.success) {
                    alert(response.data)
                    this.setState({ formIsValid: false, submittedState: true })
                } else {
                    enqueueSnackbar(response.data, { variant: 'error' })
                }

                this.setState({ loading: false });

            }).catch(error => {
                enqueueSnackbar((error && error.message) || 'Oops! Something went wrong. Please try again!', { variant: 'error' })
                this.setState({ loading: false });
            });
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.minLength) {
            isValid = value.length >= rules.minLength && isValid
        }

        if (rules.maxLength) {
            isValid = value.length <= rules.maxLength && isValid
        }

        if (rules.isEmail) {
            const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.isNumeric) {
            const pattern = /^\d+$/;
            isValid = pattern.test(value) && isValid
        }

        if (rules.select) {
            isValid = value.trim() !== '--Select--' && isValid;
        }

        return isValid;
    }

    inputChangedHandler = (event, inputIdentifier) => {
        const updatedReportForm = {
            ...this.state.reportForm
        };
        const updatedFormElement = {
            ...updatedReportForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updatedReportForm[inputIdentifier] = updatedFormElement;

        let formIsValid = true;
        for (let inputIdentifier in updatedReportForm) {
            formIsValid = updatedReportForm[inputIdentifier].valid && formIsValid;
        }

        //console.log(updatedReportForm)
        this.setState({ reportForm: updatedReportForm, formIsValid: formIsValid });
    }

    render() {
        const formElementsArray = [];
        for (let key in this.state.reportForm) {
            formElementsArray.push({
                id: key,
                config: this.state.reportForm[key]
            });
        }
        let form = (
            <form onSubmit={this.generateReport}>
                {formElementsArray.map(formElement => {
                    return (
                        <div key={formElement.id} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: 10 }}>
                            <label className="input-label">{formElement.config.elementConfig.placeholder}</label>
                            <Input
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                invalid={!formElement.config.valid}
                                shouldValidate={formElement.config.validation}
                                touched={formElement.config.touched}
                                changed={(event) => this.inputChangedHandler(event, formElement.id)} />
                        </div>
                    )
                }
                )}
                {/* <Button btnType="Success" disabled={!this.state.formIsValid}>Submit</Button> */}
                <Button variant="contained" disabled={!this.state.formIsValid} style={{ marginTop: 10 }}
                    type="submit"
                >Submit</Button>
            </form>
        );
        if (this.state.loading) {
            form = <LoadingIndicator />;
        }
        return (
            <div className="report-container">
                <h1 className="report-title">{this.state.reportName}</h1>
                <div className="report-form">
                    {form}
                </div>
                {this.state.submittedState && <FormHelperText error sx={{ mt: 3, textAlign: 'center' }}>Request submitted. Please Wait and click 'Refresh List' after sometime.</FormHelperText>}
                <FilesTable rows={[]} reportDetails={{ reportName: this.state.jasperFileName, reportFormat: this.state.reportFormat }} />
            </div>
        );
    }
}

export default ReportForm;


//orderform example:
/* reportForm: {
           name: {
               elementType: 'input',
               elementConfig: {
                   type: 'text',
                   placeholder: 'Your Name'
               },
               value: '',
               validation: {
                   required: true
               },
               valid: false,
               touched: false
           },
           street: {
               elementType: 'input',
               elementConfig: {
                   type: 'text',
                   placeholder: 'Street'
               },
               value: '',
               validation: {
                   required: true
               },
               valid: false,
               touched: false
           },
           zipCode: {
               elementType: 'input',
               elementConfig: {
                   type: 'text',
                   placeholder: 'ZIP Code'
               },
               value: '',
               validation: {
                   required: true,
                   minLength: 5,
                   maxLength: 5,
                   isNumeric: true
               },
               valid: false,
               touched: false
           },
           country: {
               elementType: 'input',
               elementConfig: {
                   type: 'text',
                   placeholder: 'Country'
               },
               value: '',
               validation: {
                   required: true
               },
               valid: false,
               touched: false
           },
           email: {
               elementType: 'input',
               elementConfig: {
                   type: 'email',
                   placeholder: 'Your E-Mail'
               },
               value: '',
               validation: {
                   required: true,
                   isEmail: true
               },
               valid: false,
               touched: false
           },
           deliveryMethod: {
               elementType: 'select',
               elementConfig: {
                   options: [
                       { value: 'fastest', displayValue: 'Fastest' },
                       { value: 'cheapest', displayValue: 'Cheapest' }
                   ]
               },
               value: '',
               validation: {},
               valid: true
           }
       }, */