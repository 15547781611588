import Assignment from '@mui/icons-material/Assignment';
import Home from '@mui/icons-material/Home';
import CloudUpload from '@mui/icons-material/CloudUpload';
import Map from '@mui/icons-material/Map';
import AccountBox from '@mui/icons-material/AccountBox';
import ExitToApp from '@mui/icons-material/ExitToApp';
import LockOpen from '@mui/icons-material/LockOpen';

export const ReportMenu = {
    "admin": [
        // {
        //     "name": "Processing Order List(For Lazydoor)",
        //     "key": "DeliveryCheckList_Lazydoor",
        //     "icon": ""
        // },
        {
            "name": "Processing Order List (Logibee Upload)",
            "key": "OrderList_Logibee",
            "icon": ""
        },
        // {
        //     "name": "Processing Order List (Woohoo-Logibee Upload)",
        //     "key": "OrderList_Woohoo_Logibee",
        //     "icon": ""
        // },
        {
            "name": "Delivery CheckList",
            "key": "DeliveryCheckList",
            "icon": ""
        },
        {
            "name": "Grinding CheckList",
            "key": "GrindingCheckList",
            "icon": ""
        },
        {
            "name": "Packaging CheckList",
            "key": "PackagingCheckList",
            "icon": ""
        },
        /* {
            "name": "Daily Product Vs Quantity",
            "key": "DailyProductVsQuantity",
            "icon": ""
        }, */
        // {
        //     "name": "Product Wise Quantity Of Processing Orders",
        //     "key": "ProdcutWiseQuantity",
        //     "icon": ""
        // },
        {
            "name": "Pickup From Store: Delivery CheckList",
            "key": "DeliveryCheckListPickupFS",
            "icon": ""
        },
        {
            "name": "Pickup From Store: Grinding CheckList",
            "key": "GrindingCheckListPickupFS",
            "icon": ""
        },
        {
            "name": "Pickup From Store: Packaging CheckList",
            "key": "PackagingCheckListPickupFS",
            "icon": ""
        },
        {
            "name": "Payment Reconciliation Report",
            "key": "ReconReport",
            "icon": ""
        },
        {
            "name": "Order List For Dunzo Scheduling",
            "key": "OrderList_dunzo",
            "icon": ""
        },
        {
            "name": "Delivery Exec Distance",
            "key": "DeliveryExecDistance",
            "icon": ""
        },
        // {
        //     "name": "Wooho: Packaging CheckList",
        //     "key": "PackagingCheckList_Woohoo",
        //     "icon": ""
        // },
        // {
        //     "name": "Wooho: Delivery CheckList",
        //     "key": "DeliveryCheckList_Woohoo",
        //     "icon": ""
        // },
        {
            "name": "Packet Counts (Home Delivery, Processing Orders)",
            "key": "ProcessingOrdersPackets",
            "icon": ""
        },
        {
            "name": "COD Recon (Delivery Partners)",
            "key": "COD_Recon",
            "icon": ""
        },
    ],
    "store_manager": [
        {
            "name": "Delivery CheckList",
            "key": "DeliveryCheckList",
            "icon": ""
        },
        {
            "name": "Grinding CheckList",
            "key": "GrindingCheckList",
            "icon": ""
        },
        {
            "name": "Packaging CheckList",
            "key": "PackagingCheckList",
            "icon": ""
        },
        // {
        //     "name": "Product Wise Quantity For Processing Orders",
        //     "key": "ProdcutWiseQuantity",
        //     "icon": ""
        // },
        {
            "name": "Pickup From Store: Delivery CheckList",
            "key": "DeliveryCheckListPickupFS",
            "icon": ""
        },
        {
            "name": "Pickup From Store: Grinding CheckList",
            "key": "GrindingCheckListPickupFS",
            "icon": ""
        },
        {
            "name": "Pickup From Store: Packaging CheckList",
            "key": "PackagingCheckListPickupFS",
            "icon": ""
        },
        {
            "name": "Delivery Exec Distance",
            "key": "DeliveryExecDistance",
            "icon": ""
        },
        {
            "name": "Packet Counts (Home Delivery, Processing Orders)",
            "key": "ProcessingOrdersPackets",
            "icon": ""
        },
        {
            "name": "COD Recon (Delivery Partners)",
            "key": "COD_Recon",
            "icon": ""
        },
        {
            "name": "Order List For Dunzo Scheduling",
            "key": "OrderList_dunzo",
            "icon": ""
        },
    ],
    "delivery_manager":
        [
            // {
            //     "name": "Processing Order List(For Lazydoor)",
            //     "key": "DeliveryCheckList_Lazydoor",
            //     "icon": ""
            // },
            {
                "name": "Processing Order List (Logibee Upload)",
                "key": "OrderList_Logibee",
                "icon": ""
            },
            // {
            //     "name": "Processing Order List (Woohoo-Logibee Upload)",
            //     "key": "OrderList_Woohoo_Logibee",
            //     "icon": ""
            // },
            {
                "name": "Delivery CheckList",
                "key": "DeliveryCheckList",
                "icon": ""
            },
            {
                "name": "Order List For Dunzo Scheduling",
                "key": "OrderList_dunzo",
                "icon": ""
            },
            {
                "name": "Delivery Exec Distance",
                "key": "DeliveryExecDistance",
                "icon": ""
            },
            {
                "name": "COD Recon (Delivery Partners)",
                "key": "COD_Recon",
                "icon": ""
            },
        ],
    "Delivery_person": [
        {
            "name": "Delivery CheckList",
            "key": "DeliveryCheckList",
            "icon": ""
        }
    ],
    "marketing": [
        {
            "name": "Daily Orders",
            "key": "DailyOrders",
            "icon": ""
        }
    ],
    "loggedOut": [],
    "wholemark": [
        // {
        //     "name": "Processing Order List",
        //     "key": "OrderList_Logibee",
        //     "icon": ""
        // },  
    ],
};


export const MenuList1 = {
    "admin": [
        /* {
            "name": "Home",
            "path": "/",
            "icon": "Home"
        }, */
        {
            "name": "Reports",
            "path": "/Checklists",
            "icon": <Assignment />
        },
        {
            "name": "Upload Routing File",
            "path": "/routing",
            "icon": <CloudUpload />
        },
        {
            "name": "View Routing",
            "path": "/viewRouting",
            "icon": <Map />
        },
        {
            "name": "Upload Recon File",
            "path": "/recon",
            "icon": <CloudUpload />
        },
    ],
    "store_manager": [
        {
            "name": "Reports",
            "path": "/Checklists",
            "icon": <Assignment />
        },
        {
            "name": "View Routing",
            "path": "/viewRouting",
            "icon": <Map />
        }
    ],
    "delivery_manager":
        [
            {
                "name": "Reports",
                "path": "/Checklists",
                "icon": <Assignment />
            },
            {
                "name": "Upload Routing File",
                "path": "/routing",
                "icon": <CloudUpload />
            },
            {
                "name": "View Routing",
                "path": "/viewRouting",
                "icon": <Map />
            }
        ],
    "delivery_person": [
        {
            "name": "Reports",
            "path": "/Checklists",
            "icon": <Assignment />
        },
        {
            "name": "View Routing",
            "path": "/viewRouting",
            "icon": <Map />
        }
    ],
    "marketing": [
        {
            "name": "Reports",
            "path": "/Checklists",
            "icon": <Assignment />
        }
    ],
    "loggedOut": [
    ],
    "wholemark": [
        {
            "name": "Reports",
            "path": "/Checklists",
            "icon": <Assignment />
        }
    ],
};


export const MenuList2 = {
    "loggedIn": [
        {
            "name": "Profile",
            "path": "/profile",
            "icon": <AccountBox />
        },
        {
            "name": "Logout",
            "path": "/",
            "icon": <ExitToApp />
        }
    ],
    "loggedOut": [
        {
            "name": "Login",
            "path": "/login",
            "icon": <LockOpen />
        }
    ]
};